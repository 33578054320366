<template>
	<div class="order_wrap">
		<v-container class="order_home px-6">
			<div class="txt_box mt-8">
				<p>
					<span class="txt_body1"
						>{{ name }} 고객님의 계약서입니다. 계약서 열람 비밀번호는 생년월일
						6자리 또는 사업자등록번호 입니다 ex.890502
					</span>
				</p>
			</div>
			<div class="button_box  mt-9">
				<div class="tit_03">계약서 목록</div>
				<div
					class="list_vertical_01"
					v-for="(item, i) in orderList"
					:key="i"
					@click="downEc(item)"
				>
					<div class="label">주문번호 {{ item.ZORDER_NO }}</div>
					<div class="btn_type_01">
						<p class="btn_tit">
							<strong class="primary--text">{{ item.GOODS_NM }}</strong>
						</p>
					</div>
				</div>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	async mounted() {
		const { result } = this.$route.params
		this.orderList = result.T_LIST
		this.name = result.E_NAME
	},
	components: {},
	data() {
		return {
			orderList: [{}],
			name: ''
		}
	},
	methods: {
		downEc(item) {
			const link = `${process.env.VUE_APP_NEW_SIGN_URL}/smarteform/reqClientDown/pdfDown.jcg?filePath=${item.ZPDF_PATH}&fileName=ENC_${item.ZPDF}`
			location.href = link
		}
	}
}
</script>

<style scoped lang="scss"></style>
